var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"institution-pane"}},[_c('div',{staticClass:"dash-side-container dash-side-container-topNavbar"},[_c('ToolBar',{attrs:{"title":_vm.$route.meta.pageTitle},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"loading":_vm.pageLoading || _vm.isLoading || _vm.inProgress,"small":"","color":"accent","icon":"","fab":""},on:{"click":function($event){return _vm.$store.dispatch('users/listFavorites')}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("cloud_done")])])]}}])},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Get Latest Changes")])])]},proxy:true}])})],1),_c('v-row',{staticClass:"container-view",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap flex-shrink-1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[(!_vm.internetState)?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap pa-3"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1"},[(_vm.pageLoading)?_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ProgressBar',{style:({ height: '10vh' }),attrs:{"size":40,"center":true}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-wrap flex-shrink-1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1 flex-wrap"},[_c('div',{staticClass:"d-flex flex-row mx-1"},[_c('div',{staticClass:"d-flex flex-column flex-full flex-grow-1 flex-shrink-1"},[(_vm.favorites.length > 0)?_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap"},_vm._l((_vm.favorites),function(ref,index){
var eventId = ref.eventId;
var productId = ref.productId;
var userId = ref.userId;
return _c('div',{key:'purchased-tickets' + index,staticClass:"d-flex flex-row white vx-shadow rounded-md ticket ticket-title ma-1"},[(eventId)?[_c('div',{staticClass:"d-flex flex-column align-end"},[(eventId.gallery.length > 0)?_c('img',{staticClass:"rounded-md",attrs:{"src":eventId.gallery[0].securePhotoUrl,"width":"150","height":"100%"}}):_vm._e()]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 pa-2"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('h3',{staticClass:"ft font-weight-medium text-md text-uppercase text-shadow"}),_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v(" "+_vm._s(_vm._f("timestamp")(eventId.noticesValidFor.startDateTime,'Do,MMM YYYY'))+" - "+_vm._s(_vm._f("timestamp")(eventId.noticesValidFor.endDateTime,'Do,MMM YYYY')))]),_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v(_vm._s(eventId.name))])])]),_c('div',{staticClass:"d-flex flex-column pa-2"},[_c('v-btn',{attrs:{"disabled":_vm.inProgress,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.addFavorite(eventId._id)}}},[_c('i',{staticClass:"material-icons-outlined",class:[
                                      _vm.favoriteIDs.includes(eventId._id)
                                        ? 'red--text'
                                        : '' ]},[_vm._v(_vm._s(_vm.favoriteIDs.includes(eventId._id) ? 'favorite' : 'favorite_border'))])])],1)]:_vm._e()],2)}),0):_c('EmptyPage',{staticClass:"mt-8",attrs:{"image":"https://res.cloudinary.com/llolollolo/image/upload/v1709205962/wear_ghana/empty_placeholder.png","title":"No favorites available to display.","subTitle":"All your favorites will show up here"}})],1)])])])])])]):_c('InternetConnection',{scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{staticClass:"animate__animated animate__fadeIn",attrs:{"rounded":"","depressed":"","color":"primary"}},[_c('span',{staticClass:"ft font-weight-medium text-sm text-capitalize"},[_vm._v("Try Again")])])]},proxy:true}])})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }